<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";
import { LicenseManager } from "ag-grid-enterprise";
import { connectToServerApi } from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import { onMounted, ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../../utils/dateUtils";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import OutboundOrderLayout from "@/layouts/OutboundOrderLayout.vue";
import useOutboundOrders from "@/hooks/useOutboundOrders";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

let gridApi;
let context = ref(null);

const { outboundOrder, fetchOutboundOrder } = useOutboundOrders();
const userStore = useUserStore();
const route = useRoute();
const themeStore = useThemeStore();

const OUTBOUND_ORDERS_HISTORY_URL = `/outbound_orders/${route.params.id}/order_history`;

const advancedFilterKeys = ["user.name", "created_at", "body"];

onMounted(async () => {
  await fetchOutboundOrder(route.params.id).then(() => {
    setPageTitle(`Outbound Order #OUT-${outboundOrder.value.id}`);
  });
});

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: OUTBOUND_ORDERS_HISTORY_URL,
    advancedFilterKeys: advancedFilterKeys,
  };
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
}

const columnDefs = [
  { headerName: "#", cellDataType: "text", valueGetter: "node.rowIndex + 1", filter: false, sortable: false, floatingFilter: false, width: 50},
  { field: "user.name", headerName: "Name", colId: "user.name", cellDataType: "text",
    cellRenderer: function (params) {
      if (params.data.user.name) {
        return `<a href="/users/${params.data.user.id}">${params.data.user.name}</a>`;
      } else {
        return '';
      }
    },
  },
  {
    field: "created_at", headerName: "Date", colId: "created_at", cellDataType: "text", valueGetter: (params) => {
      return shortDateWithTimeTz(params.data.created_at);
    }
  },
  { field: "body", headerName: "Action", colId: "body", cellDataType: "text", },
];

const defaultColDef = getDefaultColDefs();

const gridOptions = getDefaultGridOptions(columnDefs);
</script>

<template>
  <OutboundOrderLayout :outboundOrder="outboundOrder" v-if="outboundOrder">
    <div v-if="userStore.user && outboundOrder" class="px-4">
      <div class="w-100 px-2">
        <div class="w-100">
          <OrderHeader
            orderType="outbound"
            :orderId="outboundOrder.id"
            :status="outboundOrder.order_status.short_label"
          />
        </div>

        <v-card class="mt-4 pa-2 w-auto" elevation="0">
          <v-card-title class="text-h5">Order History</v-card-title>
        </v-card>

        <div class="outbound-order-history">
          <AgGridVue
            style="width: 100%; height: 100%"
            :class="themeStore.agTheme"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :context="context"
          ></AgGridVue>
        </div>
      </div>
    </div>
  </OutboundOrderLayout>
</template>

<style scoped>
.outbound-order-history {
  height: 665px;
  margin-top: 15px;
}
</style>